<template>
	<div>
		<a href="#">
			<iframe :src="pdf" type="application/pdf" download="stestes" width="100%" height="1095px"></iframe>
		</a>
	</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import generateSrfPdf from '@/view/content/lib/srfPDF';

export default {
	name: '',
	data() {
		return {
			loaded: false,
			data: null,
			pdf: null,
		};
	},
	created() {
		this.loaded = false;
		this.$http.get(`srf/${this.$route.params.id}`).then(resp => {
			this.data = resp.data.data;
			generateSrfPdf(this.data.data, pdf => {
				pdf.getDataUrl(url => {
					this.pdf = url;
				});
			});
			this.$store.dispatch(SET_BREADCRUMB, [{ title: 'SRF List', route: { name: 'srflist' } }, { title: 'SRF - ' + this.data.data.customer_name }]);
			this.loaded = true;
		});
	},
};
</script>

<style></style>
